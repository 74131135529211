import type { App } from 'vue'

import { Edit, Search, AddLocation, Loading } from '@element-plus/icons-vue'

export const icons = [Edit, Search, AddLocation, Loading]

// 按需导出图标组件
export default (app: App): void => {
  for (const icon of icons) {
    app.component(icon.name, icon)
  }
}
